import type { HeadFC } from 'gatsby'
import React from 'react'

import { LanguageHeaders } from '../components/LanguageHeaders'
import Layout from '../components/Layout'
import PrivacyPolicy_DE from '../content/de/privacyPolicy.mdx'
import PrivacyPolicy_EN from '../content/en/privacyPolicy.mdx'
import PrivacyPolicy_ES from '../content/es/privacyPolicy.mdx'
import PrivacyPolicy_FR from '../content/fr/privacyPolicy.mdx'
import PrivacyPolicy_IT from '../content/it/privacyPolicy.mdx'

const PrivacyPolicyPage = ({ pageContext }: any) => {
  const language = pageContext.language

  return (
    <Layout light>
      <main className="m-auto w-full lg:max-w-screen-lg grid grid-flow-row p-4 lg:px-0 pt-24 prose">
        {language === 'en' && <PrivacyPolicy_EN />}
        {language === 'it' && <PrivacyPolicy_IT />}
        {language === 'fr' && <PrivacyPolicy_FR />}
        {language === 'es' && <PrivacyPolicy_ES />}
        {language === 'de' && <PrivacyPolicy_DE />}
      </main>
    </Layout>
  )
}

export default PrivacyPolicyPage

export const Head: HeadFC = ({ pageContext }: any) => {
  const language = pageContext.language
  const translations: any = pageContext.translations
  return (
    <>
      <title>{translations['legal.privacy_policy.title']}</title>
      <meta name="description" content={translations['legal.privacy_policy.description']} />
      <meta name="robots" content="index, follow" />
      <LanguageHeaders pathname={pageContext.defaultPath} locale={language} />
    </>
  )
}
